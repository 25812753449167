import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.socialMediaLinkContainer, "flex between align-c"]),
    style: _normalizeStyle({ flexDirection: _ctx.orientation as 'row' | 'column', position: _ctx.positionOverride as 'absolute' | 'initial' })
  }, [
    _createElementVNode("a", {
      href: "https://github.com/sbyardnss",
      target: "_blank",
      class: _normalizeClass([_ctx.$style.socialMediaIcon, "devicon-github-original"])
    }, null, 2),
    _createElementVNode("a", {
      href: "https://www.linkedin.com/in/stephen-byard/",
      target: "_blank",
      class: _normalizeClass([_ctx.$style.socialMediaIcon, "devicon-linkedin-plain"])
    }, null, 2),
    _createElementVNode("a", {
      class: _normalizeClass(_ctx.$style.socialMediaIcon),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDownloadResume && _ctx.handleDownloadResume(...args)))
    }, "CV", 2)
  ], 6))
}