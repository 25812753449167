import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "light-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SocialMedia = _resolveComponent("SocialMedia")!
  const _component_EmailForm = _resolveComponent("EmailForm")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.contactPageContainer, "flex column align-c"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createTextVNode(" email: "),
      _createElementVNode("a", {
        class: _normalizeClass(_ctx.$style.emailLink),
        href: "mailto:stephenjbyard@gmail.com"
      }, "stephenjbyard@gmail.com", 2)
    ]),
    (_ctx.isMobileView)
      ? (_openBlock(), _createBlock(_component_SocialMedia, {
          key: 0,
          orientation: 'row',
          "position-override": 'initial'
        }))
      : (_openBlock(), _createBlock(_component_SocialMedia, { key: 1 })),
    _createVNode(_component_EmailForm)
  ], 2))
}