import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "portfolio-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Nav, { id: "navContainer" }),
    (_openBlock(), _createBlock(_component_RouterView, {
      key: _ctx.$route.fullPath,
      id: "routerContainer"
    }))
  ]))
}