import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id", "src", "onmouseover", "onmouseout"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      id: `imageDisplayBasic + ${_ctx.imageUrl}`,
      class: _normalizeClass(_ctx.imageClass),
      src: _ctx.imageUrl,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleZoomChange && _ctx.handleZoomChange(...args))),
      style: _normalizeStyle({ height: _ctx.imageHeight, }),
      onmouseover: _ctx.handleHoverZoom,
      onmouseout: _ctx.handleHoverUnzoom
    }, null, 14, _hoisted_1),
    (_ctx.zoomedImage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "zoomContainer",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleZoomChange && _ctx.handleZoomChange(...args)))
        }, [
          _createElementVNode("img", {
            class: "zoomedImage",
            src: _ctx.imageUrl
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}