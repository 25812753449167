import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.frontEndLink)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "btn-action flex align-c between",
          href: _ctx.frontEndLink,
          target: "_blank"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.$style.socialMediaIcon, "devicon-github-original"])
          }, null, 2),
          _createTextVNode(" client ")
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.backEndLink)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          class: "btn-action flex align-c between",
          href: _ctx.backEndLink,
          target: "_blank"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.$style.socialMediaIcon, "devicon-github-original"])
          }, null, 2),
          _createTextVNode(" server ")
        ], 8, _hoisted_2))
      : _createCommentVNode("", true)
  ], 64))
}